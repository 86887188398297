@charset "utf-8";
@import "../Variables.scss";

.section-trade {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  .content {
    padding: 40px;
    transition-duration: 0.5s;
    background-color: white;
    color: $primary;

    @include desktop {
      min-height: 400px;
    }

    &:hover {
      box-shadow: 10px 10px $black;
    }
  }

  .subtitle {
    margin: 40px 0;
    font-size: 14px;
  }

  img.dexImg {
    transition-duration: 2s;
    &:hover {
      transform: scale(1.05);
    }
  }

  a.trade-with-api {
    display: block;
    padding-top: 12px;
    font-size: 12px;
  }
  a.trade-now {
    user-select: none;
    background-color: $primary;
    display: inline-block;
    padding: 15px 40px;
    transition-duration: 0.5s;
    color: $white;
    margin-top: 0px;
    min-width: 300px;
    border-radius: 4px;

    &:after {
      transition-duration: 0.5s;
      content: "⟶";
      opacity: 0;
      font-size: 0;
      padding-left: 0px;
      color: $black;
    }

    &:hover {
      text-decoration: none;
      color: $white;
      background-color: $primary;

      &:after {
        opacity: 1;
        font-size: inherit;
        padding-left: 10px;
        color: $white;
      }
    }
  }
}
