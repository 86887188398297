@charset "utf-8";

@import "../Variables.scss";

.page-notfound {
  * {
    user-select: none;
  }
  h1 {
    margin-top: 20px;
  }
}
