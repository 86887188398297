@charset "utf-8";

@import "../Variables.scss";

.section-article-group {
  min-height: 50vh;
  .columns {
    @include desktop {
      padding-right: 10%;
      padding-left: 10%;
    }
  }
}
