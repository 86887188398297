@font-face {
  font-weight: normal;
  font-family: "DINCondensed-Regular";
  src: url("/fonts/DINCondensed/363123_2_0.eot");
  src: url("/fonts/DINCondensed/363123_2_0.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/DINCondensed/363123_2_0.woff2") format("woff2"),
    url("/fonts/DINCondensed/363123_2_0.woff") format("woff"),
    url("/fonts/DINCondensed/363123_2_0.ttf") format("truetype"),
    url("/fonts/DINCondensed/363123_2_0.svg#wf") format("svg");
}

@font-face {
  font-family: "Montserrat-Regular";
  font-weight: normal;
  font-style: normal;
  src: local("Montserrat-Regular"),
    url(/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Italic";
  font-weight: normal;
  font-style: normal;
  src: local("Montserrat-Italic"),
    url(/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-weight: normal;
  font-style: normal;
  src: local("Montserrat-Medium"),
    url(/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-weight: bold;
  font-style: normal;
  src: local("Montserrat-Bold"),
    url(/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  font-weight: 300;
  font-style: normal;
  src: local("Montserrat-Light"),
    url(/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

html,
body {
  font-family: Montserrat, sans-serif !important;
}
@import "./Variables.scss";
@import "bulma/bulma.sass";
//@import "../node_modules/bulma/sass/utilities/_all.sass";
////@import "~/bulma/bulma.sass";
////@import "sass/helpers/_all.sass";
//@import "../node_modules/bulma/sass/helpers/_all.sass";
//@import "../node_modules/bulma/sass/components/_all.sass";
//@import "../node_modules/bulma/sass/elements/_all.sass";
//@import "../node_modules/bulma/sass/grid/_all.sass";
//@import "../node_modules/bulma/sass/form/_all.sass";
//@import "../node_modules/bulma/sass/layout/_all.sass";

.highlighted {
  background-image: url("/images/css/highlight.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none;

  &.underline {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
    // text-decoration-line: underline !important;
    // text-underline-position: under !important;
  }

  &.highlight {
    &:hover {
      background-image: url("/images/css/highlight.svg") !important;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.arrow {
  }
}

h2 {
  color: $black;
  font-family: $theme-font;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include mobile {
    margin-top: 10px;
    font-weight: 700 !important;
  }
}

h3 {
  text-decoration-style: solid !important;
}

h4 {
  text-decoration-style: dotted !important;
}

h1 {
  color: $black;
  // letter-spacing: 0.08em;
  font-family: $theme-font;
  text-transform: uppercase;
  // letter-spacing: 0.1em;
  font-weight: 700 !important;
  // padding-bottom: 40px !important;
  font-size: 2.8rem !important;
}

.has-text-din {
  font-family: $theme-font;
}

.has-text-justify {
  text-align: justify;
}

.has-background-blue {
  background-color: $very-light-background !important;
  color: $black;
}

.has-background-primary-dark {
  background-color: $dark-blue !important;
  color: $white;
}

.has-background-grey {
  background-color: #fafafa !important;
  color: $black;
}

.has-background-black {
  background-color: $black !important;
}

.has-background-primary {
  background-color: $primary !important;
}

.has-text-primary {
  color: $primary !important;
}

.has-background-black,
.has-background-primary-dark,
.has-background-primary {
  color: $white;

  h1,
  h2,
  h3,
  h4,
  a,
  a:hover {
    color: $white !important;
  }
}

.has-leading-thin-arrow {
  &:before {
    content: "⟶";
    display: inline-block;
    white-space: pre-wrap;
    margin-right: 4px;
  }
}

.has-leading-thick-arrow {
  &:before {
    content: "⟶";
    display: inline-block;
    white-space: pre-wrap;
    margin-right: 4px;
  }
}

.has-trailing-arrow {
  &:after {
    content: "↗";
    display: inline-block;
    // white-space: pre-wrap;
    // margin-right: 4px;
  }
}

.covered {
  img {
    user-select: none;
    filter: grayscale(100%);
    transition-duration: 1s;
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }

  &:after {
    transition-duration: 1s;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($image-overlay-background, 0.1);
    background-image: linear-gradient(
        45deg,
        $image-overlay-foreground 5%,
        transparent 15%,
        transparent 85%,
        $image-overlay-foreground 45%,
        $image-overlay-foreground
      ),
      linear-gradient(
        45deg,
        $image-overlay-foreground 5%,
        transparent 15%,
        transparent 85%,
        $image-overlay-foreground 45%,
        $image-overlay-foreground
      ),
      linear-gradient(
        -45deg,
        $image-overlay-foreground 5%,
        transparent 15%,
        transparent 85%,
        $image-overlay-foreground 45%,
        $image-overlay-foreground
      ),
      linear-gradient(
        -45deg,
        $image-overlay-foreground 5%,
        transparent 15%,
        transparent 85%,
        $image-overlay-foreground 45%,
        $image-overlay-foreground
      );
    background-size: 3px 3px;
  }
}
