@charset "utf-8";

@import "../Variables.scss";

.setup-participant {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
  margin-top: 4px !important;
  font-size: 0.9rem;
  font-weight: 700;

  &.gitcoin {
    // background-color: #3fafff;
    color: gold;
  }

  &.done {
    background-color: $black;
    color: #3fafff;
    .icon {
      color: rgba(0, 255, 0, 0.8);
    }
  }

  &.running {
    color: $primary;
    background-color: $white;

    .icon {
      color: $primary;
    }
  }

  .hash {
    font-family: monospace;
    overflow: hidden;
  }
}
