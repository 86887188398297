@charset "utf-8";

@import "../Variables.scss";

.column-events {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.section-sub-nav {
  border-top: 0.5px solid rgba(255, 255, 255, 0.4);
  padding: 10px !important;

  li {
    display: inline-block;
  }

  a.navbar-item {
    // background-color: $grey-lighter;
    color: $white;
    display: inline-block;
    user-select: none;
    padding-left: 3px;
    padding-right: 3px;
    transition-duration: 0.5s;
    font-weight: 500;
    font-size: $body-size;
    text-decoration: none;
    box-shadow: inset 0 -2px transparent;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      box-shadow: inset 0 -2px $white;
    }

    &.active {
      color: $grey-light;
      box-shadow: inset 0 -2px $grey-light;
    }
  }
}
