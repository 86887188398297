@charset "utf-8";

@import "../Variables.scss";

.section-protocol-deployment {
  .columns-options {
    margin-top: 40px;

    .content {
      padding: 40px;
      transition-duration: 0.5s;

      @include desktop {
        min-height: 400px;
      }

      h2 {
        border-bottom: 4px solid $grey;
        text-align: center;
        padding-bottom: 8px;
        margin-bottom: 20px;
      }

      &:hover {
        box-shadow: 10px 10px $grey-light;

        h2 {
          color: $white !important;
          border-bottom: 4px solid $white;
        }

        background-color: $black !important;
        color: $white !important;
      }
    }
  }
}
