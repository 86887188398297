@charset "utf-8";
@import "../Variables.scss";

.footer {
  padding-bottom: 1rem !important;

  * {
    user-select: none;
  }

  ul {
    list-style-type: none;

    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      display: inline-block;

      @include mobile {
        display: block;
      }
    }
  }

  // border-top: 1px solid $grey-lighter;
  background-color: white;

  a {
    text-decoration: none !important;
    box-shadow: inset 0 -2px transparent;
    transition-duration: 0.5s;
    display: inline-block;
    margin: 10px;

    &:hover {
      box-shadow: inset 0 -2px $black;
    }
  }

  .org {
    padding-top: 20px;

    @include mobile {
      margin-top: 40px;
    }

    p {
      display: block;
      color: grey;
      font-size: 13px;
      margin: 10px;
    }
  }

  div.field {
    display: inline-block;
    padding: 0 10px 0 10px;
  }

  .input {
    background-color: white !important;
    color: $grey;
    min-width: 120px;
  }

  .input:focus,
  .input:active {
    border-color: $primary;
    box-shadow: 0 0 0 0.125em rgba($primary, 0.2);
    color: $black;
  }

  .button.is-link {
    background-color: $grey;
  }

  .button.is-link:hover {
    background-color: $primary;
  }
}
