@charset "utf-8";

@import "../Variables.scss";

.section-lrc-staking {
  .columns {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .category {
    font-size: 100px;
    padding: 0 40px;
    margin-top: -40px;
  }
  .category-title {
    font-size: 20px;
  }
}
