@charset "utf-8";
@import "../Variables.scss";

.section-about-versions {
  .version30 {
    margin-top: 40px;
    padding-top: 60px;
    padding-bottom: 60px;

    a,
    a:hover,
    h2 {
      color: $black !important;
    }

    @include desktop {
      box-shadow: 10px 10px $black;
      margin-top: 100px;
      padding: 60px;
    }

    b {
      color: $primary;
    }
  }
}
